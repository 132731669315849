import React from "react";
import { FaTwitter, FaFacebookF, FaYoutube, FaLinkedin } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaArrowRightLong, FaPhone, FaLocationDot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
const FooterCompo = () => {
    return (
        <>
            <div className="footerContainer">
                <section>
                    <div className="FooterContainerRow" style={{ display: 'flex', alignItems: 'center',flexWrap:"wrap" }}>
                        <div className="logo-container" style={{ flex: '0 0 auto', marginRight: '80px' }}>
                            <a href="/" className="logo" id="nav_iconid">
                                <img src="https://res.cloudinary.com/deit9bc5b/image/upload/v1713708420/sp_logo_doxyim.png" alt="Sipora Coffee Logo" style={{ height: "120px" }} />
                            </a>
                        </div>
                        <div className="text-container" style={{ flex: '1' }}>
                            <p className="footerPara">
                                We strongly believe in ensuring the excellence of our products by actively seeking and valuing customer feedback. By continuously soliciting input and sharing customer reviews on our website, we
                                strive to maintain the highest quality standards.
                            </p>
                        </div>
                    </div>

                    <div className="box-container">
                        <div className="box">
                            <h3>quick links</h3>
                            <a href="https://res.cloudinary.com/deit9bc5b/image/upload/v1713708541/Terms_and_Conditions_unbvi4.pdf"><i><FaArrowRightLong /></i> Terms and Conditions</a>
                            <a href="https://res.cloudinary.com/deit9bc5b/image/upload/v1713708543/SC-policy_osbx1s.pdf"><i><FaArrowRightLong /></i> Policy</a>
                        </div>
                        <div className="box">
                            <h3>Social Media</h3>
                            <a href="/"> <i> <FaFacebookF /></i>facebook</a>
                            <a href="https://www.instagram.com/coffee__sipora?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="> <i> <FaInstagramSquare /></i>instagram</a>
                        </div>
                        <div className="box">
                            <h3>Contact Info</h3>s
                            <a href="/"> <i> <FaLocationDot /></i> Shop No.4, Mahagun Mirabella, Noida Sector 79</a>
                            <a href="/"><i><FaPhone /></i> +91 80769 57794</a>
                            <a href="/"> <i><IoMdMail /></i><span style={{ textTransform: 'lowercase' }}>siporacoffee@gmail.com</span></a>
                        </div>

                    </div>
                </section>
                <div className="footerBox">
                    <p> copyright @2024 Sipora coffee  , All Right Reserved </p>
                </div>
            </div>
        </>
    );
};

export default FooterCompo;
