import React, { useState } from 'react'
import MenuHeader from '../components/MenuComp/MenuHeader'
import Menu from '../components/MenuComp/Menu'
import foodData from "../api";

function MainMenu() {
    const [selectedCategory, setSelectedCategory] = useState(foodData.foodTypes[0]); 
    const [Mindex, setMindex] = useState(0)
    const handleCategorySelect = (category,index) => {
        setMindex(index)
        setSelectedCategory(category);
    };
    return (
        <>
            <div className="MenuContainer">
                <MenuHeader onCategorySelect={handleCategorySelect}/>
                <Menu selectedCategory={selectedCategory} Mindex={Mindex}/>
            </div>
        </>
    )
}

export default MainMenu
