import { createContext, useReducer } from 'react';


// const card_Structure = {
//     id: "1",
//     title: "Farmhouse",
//     description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
//     foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
//     ItemSize: "Medium ",
//     variants: "New Hand Tossed",
//     price: 175,
//     quantity: 1,
// }
// const card_Structure_02 = {
//     id: "2",
//     title: "Farmhouse",
//     description: " Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
//     foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
//     ItemSize: "Small ",
//     variants: "New Hand Tossed",
//     price: 195,
//     quantity: 1,
// }

const initailState = {
    cart: [],
}

export const CartContext = createContext(initailState);

const ADD_TO_CART = 'ADD_TO_CART';
const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
const INCREMENT_QUANTITY = 'INCREMENT_QUANTITY';
const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';

// Reducer function
const cartReducer = (state, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, action.payload],
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter((item) => item.id !== action.payload),
            };
        case INCREMENT_QUANTITY:
            return {
                ...state,
                cart: state.cart.map(item => {
                    if (item.id === action.payload) {
                        return {
                            ...item,
                            quantity: item.quantity + 1
                        };
                    }
                    return item;
                })
            };
        case DECREMENT_QUANTITY:
            return {
                ...state,
                cart: state.cart.map((item) => {
                    if (item.id === action.payload) {
                        const newQuantity = item.quantity - 1;
                        if (newQuantity <= 0) {
                            return null;
                        }
                        return {
                            ...item,
                            quantity: newQuantity,
                        };
                    }
                    return item;
                }).filter((item) => item !== null), // Filter out null items
            };
        default:
            return state;
    }
};



export const CardContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initailState);
    return (
        <CartContext.Provider
            value={{
                ...state,
                dispatch,
            }}>
            {children}
        </CartContext.Provider>
    )
};