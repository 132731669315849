import About from '../components/About'
import FooterCompo from '../components/FooterCompo'
import Googlemap from '../components/Googlemap'
import Homeview from '../components/Homeview'
import '../Style/Design.scss'
const MainHome = () => {
    return (
        <>
            <Homeview />
            <About />
            <Googlemap/>
        </>
    )
}

export default MainHome
