import { RiSubtractFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { useContext } from "react";
import { CartContext } from '../../Context/CardContext';
import {FoodCategoryContext} from '../../Context/FoodCategoryContext'

function CardComp({ cardStructure }) {
    const { dispatch } = useContext(CartContext);
    const { dispatch: foodCategoryDispatch } = useContext(FoodCategoryContext);

    // console.log(cardStructure)
    const handleIncrementQuantity = () => {
        dispatch({
            type: "INCREMENT_QUANTITY",
            payload: cardStructure.id
        });
    };
    const handleDecrementQuantity = ()=>{
        if(cardStructure.quantity === 1){
            foodCategoryDispatch({
                type: "REMOVE_FOOD_CARD_WITHOUT_ID",
                payload: {
                    ItemId: cardStructure.id,
                }
            })
        }
        dispatch({
            type: "DECREMENT_QUANTITY",
            payload: cardStructure.id
        })
    }
    return (
        <>
            <div className="foodItem">
                <div className="foodFlexItem">
                    <div className="Food_flex_item_Img">
                        <img src={cardStructure.foodImg} alt="" />
                    </div>
                    <div className="Food_flex_item_content">
                        <h1 className="Food_flex_item_heading">{cardStructure.title}</h1>
                        <p className="Food_flex_item_para">
                            {cardStructure.description}
                        </p>
                        <p className="Food_flex_item_size">
                            {cardStructure.ItemSize} | {cardStructure.variants}
                        </p>
                    </div>
                </div>
                <div className="Increment_delete_item_flex">
                    <div className="Increment_delete_item">
                        <RiSubtractFill className='innerItem_img' onClick={handleDecrementQuantity} />
                        <span className='innerItem_text'>{cardStructure.quantity}</span>
                        <FaPlus className='innerItem' onClick={handleIncrementQuantity} />
                    </div>
                    <div className="Item_Price">
                        ₹ {cardStructure.price * cardStructure.quantity}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardComp
