import React, { useState } from 'react';
import imgOne from '../Image/home-img-1.png';
import imgTwo from '../Image/home-img-2.png';
import imgThree from '../Image/home-img-3.png';
const Homeview = () => {
    const [selectedImage, setSelectedImage] = useState(imgOne);
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };
    return (
        <>
            <section className="home" id="home">
                <div className="row">
                    <div className="content">
                        <h4 className = "TopHeading" style={{ fontSize: '4rem', whiteSpace: 'nowrap', paddingBottom: '40px' }}>
                            <span style={{ color: '#c98b1e' }}>Sipora blends "s</span>
                            <span style={{ color: '#d71717' }}>i</span>
                            <span style={{ color: '#c98b1e' }}>p" and "ar</span>
                            <span style={{ color: '#d71717' }}>o</span>
                            <span style={{ color: '#c98b1e' }}>ma"</span>
                        </h4>
                        <h4 style={{ fontSize: '2.5rem', paddingBottom: '80px' }}>
                            <span style={{ color: '#06607c' }} className='subText'> "evoking a coffee space that emphasizes aromatic enjoyment through sips"</span>
                        </h4>
                        <a href="/" className='btn'>Scan QR to Order</a>
                    </div>

                    <div className="image">
                        <img src={selectedImage} alt="" class="main-home-image" />
                    </div>
                </div>
                <div className="image-slider">
                    <img src={imgOne} alt="" onClick={() => handleImageClick(imgOne)} />
                    <img src={imgTwo} alt="" onClick={() => handleImageClick(imgTwo)} />
                    <img src={imgThree} alt="" onClick={() => handleImageClick(imgThree)} />
                </div>
            </section>
        </>
    )
}

export default Homeview
