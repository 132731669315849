import React, { useContext } from 'react'
import { CiHeart } from "react-icons/ci";
import { CartContext } from '../../Context/CardContext';
import { FoodCategoryContext } from '../../Context/FoodCategoryContext';

function MenuCard({ item, Mindex }) {
    const { dispatch: cardDispatch } = useContext(CartContext);
    const { dispatch: foodCategoryDispatch } = useContext(FoodCategoryContext);
    const handleAddToCart = () => {
        const insertingData = {
            id: item.id,
            title: item.title,
            description: item.description,
            foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
            ItemSize: "Small ",
            variants: "New Hand Tossed",
            price: item.price,
            quantity: 1,
        }
        cardDispatch({ type: 'ADD_TO_CART', payload: insertingData });
        foodCategoryDispatch({
            type: 'UPDATE_FOOD_CARD', payload: {
                ItemId: item.id,
                ParentId: Mindex
            }
        });
        // console.log(insertingData)
    };
    const handleRemoveCard = () => {
        console.log(item)
        cardDispatch({ type: 'REMOVE_FROM_CART', payload: item.id });
        foodCategoryDispatch({
            type: 'REMOVE_FOOD_CARD', payload: {
                ItemId: item.id,
                ParentId: Mindex
            }
        });
    }


    return (
        <>
            <div className="menu_card">
                <div className="menu_card_image">
                    {/* <img src={item.foodImg} alt="" /> */}
                    <img src="https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/4d42a594-14be-48d2-ac4a-adcd0034a08f_FierySausagePaprikadef.jpeg" alt="" />
                </div>
                <div className="menu_card_content">
                    <div className="menu_card_item1">
                        <h1 className="flex_item_heading">
                            {item.title}
                        </h1>
                        <p className="flex_item_para">
                            {item.description}
                        </p>
                    </div>
                    <div className="menu_card_button_card">
                        <span className="itemPrice">₹ {item.price}</span>
                        {item.addedToCart ? (
                            <span className="added" onClick={handleRemoveCard} >Added to Cart</span>
                        ) : (
                            <span className="addCard" onClick={handleAddToCart}>Add to Cart</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MenuCard
