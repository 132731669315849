
const foodData = {
    "foodTypes": [
        {
            type: "Hot-Coffee",
            active: false,
            image: "https://img.freepik.com/free-psd/latte-isolated-transparent-background_191095-32793.jpg?w=740&t=st=1708902195~exp=1708902795~hmac=a00477c1609eb7afd32a12939ef55bc4510afef5977a3c3358ed5ad7647e0617",
            items: [
                {
                    id: "11",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "12",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "13",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 234,
                    addedToCart: false
                },
                {
                    id: "14",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "large ",
                    variants: "New Hand Tossed",
                    price: 575,
                    addedToCart: false
                },
                {
                    id: "15",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "small ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "16",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "small ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "17",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "small ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "18",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "small ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Desserts",
            active: false,
            image: "https://img.freepik.com/premium-psd/tasty-chocolate-ice-cream-scoop-wafer-cup-isolated-transparent-background_812337-895.jpg?w=360",
            items: [
                {
                    id: "21",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "22",
                    title: "Farmhouse",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "23",
                    title: "Tiramisu",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "24",
                    title: "Tiramisu",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                },
                {
                    id: "25",
                    title: "Tiramisu",
                    description: "Delightful Combination Of Onion, Capsicum, Tomato & Grilled Mushroom",
                    foodImg: "https://images.dominos.co.in/nextgen-catalog/media/prod/Dominos/menu/10ddf620-ae20-477f-b4b2-9bce1ded021d_BlazingOnionPaprikacartmenu.jpeg",
                    ItemSize: "Medium ",
                    variants: "New Hand Tossed",
                    price: 175,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Shakes",
            active: false,
            image: "https://img.freepik.com/free-psd/delicious-chocolate-sundae-isolated-transparent-background_191095-9321.jpg?w=740&t=st=1708902102~exp=1708902702~hmac=03f9ec8020a72097683bdaf5ef9ff5e4d1919b99a6e2b13313a0805150ec747b",
            items: [
                {
                    id: "31",
                    title: "Chocolate Shake",
                    description: "Indulge in the rich and creamy goodness of chocolate with our classic chocolate shake.",
                    foodImg: "https://example.com/chocolate-shake.jpg",
                    size: "Regular",
                    price: 150,
                    addedToCart: false
                },
                {
                    id: "32",
                    title: "Vanilla Shake",
                    description: "Experience the smooth and delightful taste of vanilla with our creamy vanilla shake.",
                    foodImg: "https://example.com/vanilla-shake.jpg",
                    size: "Regular",
                    price: 140,
                    addedToCart: false
                },
                {
                    id: "33",
                    title: "Strawberry Shake",
                    description: "Savor the freshness of ripe strawberries blended into a refreshing shake.",
                    foodImg: "https://example.com/strawberry-shake.jpg",
                    size: "Regular",
                    price: 160,
                    addedToCart: false
                },
                {
                    id: "34",
                    title: "Banana Shake",
                    description: "Enjoy the natural sweetness of bananas in our delicious banana shake.",
                    foodImg: "https://example.com/banana-shake.jpg",
                    size: "Regular",
                    price: 130,
                    addedToCart: false
                },
                {
                    id: "35",
                    title: "Caramel Shake",
                    description: "Indulge in the rich flavor of caramel with our irresistible caramel shake.",
                    foodImg: "https://example.com/caramel-shake.jpg",
                    size: "Regular",
                    price: 170,
                    addedToCart: false
                },
                {
                    id: "36",
                    title: "Coffee Shake",
                    description: "Get a caffeine boost with our creamy and flavorful coffee shake.",
                    foodImg: "https://example.com/coffee-shake.jpg",
                    size: "Regular",
                    price: 180,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Cold Coffee",
            active: false,
            image: "https://img.freepik.com/free-psd/latte-isolated-transparent-background_191095-32793.jpg?w=740&t=st=1708902195~exp=1708902795~hmac=a00477c1609eb7afd32a12939ef55bc4510afef5977a3c3358ed5ad7647e0617",
            items: [
                {
                    id: "41",
                    title: "Classic Cold Coffee",
                    description: "Our classic cold coffee is made with freshly brewed coffee, milk, sugar, and ice cubes.",
                    foodImg: "https://example.com/classic-cold-coffee.jpg",
                    size: "Regular",
                    price: 120,
                    addedToCart: false
                },
                {
                    id: "42",
                    title: "Mocha Cold Coffee",
                    description: "Indulge in the rich blend of coffee and chocolate with our delicious mocha cold coffee.",
                    foodImg: "https://example.com/mocha-cold-coffee.jpg",
                    size: "Regular",
                    price: 130,
                    addedToCart: false
                },
                {
                    id: "43",
                    title: "Vanilla Cold Coffee",
                    description: "Experience the smooth and creamy taste of vanilla in our refreshing vanilla cold coffee.",
                    foodImg: "https://example.com/vanilla-cold-coffee.jpg",
                    size: "Regular",
                    price: 140,
                    addedToCart: false
                },
                {
                    id: "44",
                    title: "Caramel Cold Coffee",
                    description: "Satisfy your sweet cravings with our caramel-infused cold coffee.",
                    foodImg: "https://example.com/caramel-cold-coffee.jpg",
                    size: "Regular",
                    price: 150,
                    addedToCart: false
                },
                {
                    id: "45",
                    title: "Hazelnut Cold Coffee",
                    description: "Enjoy the nutty flavor of hazelnut in our delightful hazelnut cold coffee.",
                    foodImg: "https://example.com/hazelnut-cold-coffee.jpg",
                    size: "Regular",
                    price: 160,
                    addedToCart: false
                },
                {
                    id: "46",
                    title: "Iced Mocha Latte",
                    description: "Cool off with our refreshing iced mocha latte, perfect for coffee lovers.",
                    foodImg: "https://example.com/iced-mocha-latte.jpg",
                    size: "Regular",
                    price: 170,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Mocktails",
            active: false,
            image: "https://img.freepik.com/premium-psd/fresh-red-lemonade-drink-with-mint-leaves-transparent-background_812337-515.jpg?w=360",
            items: [
                {
                    id: "51",
                    title: "Virgin Mojito",
                    description: "A refreshing mix of lime juice, mint leaves, sugar, and soda water.",
                    foodImg: "https://example.com/virgin-mojito.jpg",
                    price: 100,
                    addedToCart: false
                },
                {
                    id: "52",
                    title: "Pina Colada",
                    description: "Escape to the tropics with this classic combination of pineapple juice and coconut cream.",
                    foodImg: "https://example.com/pina-colada.jpg",
                    price: 120,
                    addedToCart: false
                },
                {
                    id: "53",
                    title: "Strawberry Daiquiri",
                    description: "Enjoy the sweetness of strawberries in this delightful rum-based cocktail.",
                    foodImg: "https://example.com/strawberry-daiquiri.jpg",
                    price: 130,
                    addedToCart: false
                },
                {
                    id: "54",
                    title: "Mango Tango",
                    description: "A tropical delight made with fresh mangoes and a hint of lime.",
                    foodImg: "https://example.com/mango-tango.jpg",
                    price: 110,
                    addedToCart: false
                },
                {
                    id: "55",
                    title: "Blue Lagoon",
                    description: "A vibrant mix of blue curacao, vodka, and lemonade, garnished with a cherry.",
                    foodImg: "https://example.com/blue-lagoon.jpg",
                    price: 140,
                    addedToCart: false
                },
                {
                    id: "56",
                    title: "Mojito Twist",
                    description: "A unique twist on the classic mojito, featuring fresh berries and a splash of soda.",
                    foodImg: "https://example.com/mojito-twist.jpg",
                    price: 150,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Fresh Juice",
            active: false,
            image: "https://img.freepik.com/premium-psd/splash-orange-juice-glass-with-orange-slices-transparent-background_812337-1516.jpg?w=360",
            items: [
                {
                    id: "61",
                    title: "Orange Juice",
                    description: "Freshly squeezed oranges to give you a burst of vitamin C.",
                    foodImg: "https://example.com/orange-juice.jpg",
                    price: 80,
                    addedToCart: false
                },
                {
                    id: "62",
                    title: "Watermelon Juice",
                    description: "Cool and refreshing watermelon juice perfect for hot summer days.",
                    foodImg: "https://example.com/watermelon-juice.jpg",
                    price: 90,
                    addedToCart: false
                },
                {
                    id: "63",
                    title: "Pineapple Juice",
                    description: "Tangy and sweet pineapple juice straight from the tropics.",
                    foodImg: "https://example.com/pineapple-juice.jpg",
                    price: 85,
                    addedToCart: false
                },
                {
                    id: "64",
                    title: "Apple Juice",
                    description: "Crisp and refreshing apple juice made from handpicked apples.",
                    foodImg: "https://example.com/apple-juice.jpg",
                    price: 75,
                    addedToCart: false
                },
                {
                    id: "65",
                    title: "Grape Juice",
                    description: "Sweet and tangy grape juice made from the finest grapes.",
                    foodImg: "https://example.com/grape-juice.jpg",
                    price: 95,
                    addedToCart: false
                },
                {
                    id: "66",
                    title: "Mango Juice",
                    description: "Indulge in the tropical flavor of fresh mangoes with every sip.",
                    foodImg: "https://example.com/mango-juice.jpg",
                    price: 100,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Sandwich",
            active: false,
            image: "https://img.freepik.com/free-psd/blt-isolated-transparent-background_191095-23907.jpg?w=740&t=st=1708902429~exp=1708903029~hmac=c9424818c02558f45fe1468ba53fe6a98ec716e14e155b7fa24d8578a8c84182",
            items: [
                {
                    id: "71",
                    title: "Classic BLT",
                    description: "Bacon, lettuce, and tomato with mayo on toasted bread.",
                    foodImg: "https://example.com/classic-blt.jpg",
                    price: 120,
                    addedToCart: false
                },
                {
                    id: "72",
                    title: "Grilled Cheese",
                    description: "Melted cheddar cheese between two slices of buttery grilled bread.",
                    foodImg: "https://example.com/grilled-cheese.jpg",
                    price: 100,
                    addedToCart: false
                },
                {
                    id: "73",
                    title: "Club Sandwich",
                    description: "Triple-decker with turkey, bacon, lettuce, tomato, and mayo.",
                    foodImg: "https://example.com/club-sandwich.jpg",
                    price: 150,
                    addedToCart: false
                },
                {
                    id: "74",
                    title: "Vegetarian Panini",
                    description: "Grilled vegetables and mozzarella cheese pressed between ciabatta bread.",
                    foodImg: "https://example.com/vegetarian-panini.jpg",
                    price: 130,
                    addedToCart: false
                },
                {
                    id: "75",
                    title: "Tuna Salad Sandwich",
                    description: "Tuna salad with celery and mayonnaise on whole wheat bread.",
                    foodImg: "https://example.com/tuna-salad-sandwich.jpg",
                    price: 110,
                    addedToCart: false
                },
                {
                    id: "76",
                    title: "Turkey Club",
                    description: "Sliced turkey, bacon, lettuce, tomato, and mayo on toasted bread.",
                    foodImg: "https://example.com/turkey-club.jpg",
                    price: 140,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Pizza",
            active: false,
            image: "https://img.freepik.com/free-psd/pictou-county-pizza-isolated-transparent-background_191095-32844.jpg?size=626&ext=jpg&ga=GA1.1.1653169833.1708889579&semt=sph",
            items: [
                {
                    id: "81",
                    title: "Margherita",
                    description: "Classic pizza with tomato sauce, mozzarella cheese, and fresh basil.",
                    foodImg: "https://example.com/margherita.jpg",
                    price: 200,
                    addedToCart: false
                },
                {
                    id: "82",
                    title: "Pepperoni",
                    description: "Pizza topped with pepperoni slices and mozzarella cheese.",
                    foodImg: "https://example.com/pepperoni.jpg",
                    price: 220,
                    addedToCart: false
                },
                {
                    id: "83",
                    title: "Vegetarian",
                    description: "Pizza loaded with fresh vegetables like bell peppers, onions, and olives.",
                    foodImg: "https://example.com/vegetarian.jpg",
                    price: 230,
                    addedToCart: false
                },
                {
                    id: "84",
                    title: "BBQ Chicken",
                    description: "Pizza topped with barbecue sauce, grilled chicken, and onions.",
                    foodImg: "https://example.com/bbq-chicken.jpg",
                    price: 250,
                    addedToCart: false
                },
                {
                    id: "85",
                    title: "Supreme",
                    description: "The ultimate pizza with all the classic toppings: pepperoni, sausage, bell peppers, onions, and mushrooms.",
                    foodImg: "https://example.com/supreme.jpg",
                    price: 270,
                    addedToCart: false
                },
                {
                    id: "86",
                    title: "Hawaiian",
                    description: "Pizza topped with ham, pineapple, and mozzarella cheese.",
                    foodImg: "https://example.com/hawaiian.jpg",
                    price: 240,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Hot Chocolate",
            active: false,
            image: "https://img.freepik.com/free-psd/pictou-county-pizza-isolated-transparent-background_191095-32844.jpg?size=626&ext=jpg&ga=GA1.1.1653169833.1708889579&semt=sph",
            items: [
                {
                    id: "91",
                    title: "Classic Hot Chocolate",
                    description: "Smooth and creamy hot chocolate made with premium cocoa powder and milk.",
                    foodImg: "https://example.com/classic-hot-chocolate.jpg",
                    price: 150,
                    addedToCart: false
                },
                {
                    id: "92",
                    title: "Mint Hot Chocolate",
                    description: "Rich hot chocolate infused with refreshing mint flavor and topped with whipped cream.",
                    foodImg: "https://example.com/mint-hot-chocolate.jpg",
                    price: 170,
                    addedToCart: false
                },
                {
                    id: "93",
                    title: "Hazelnut Hot Chocolate",
                    description: "Decadent hot chocolate with the nutty flavor of hazelnuts and a hint of vanilla.",
                    foodImg: "https://example.com/hazelnut-hot-chocolate.jpg",
                    price: 180,
                    addedToCart: false
                },
                {
                    id: "94",
                    title: "Caramel Hot Chocolate",
                    description: "Indulgent hot chocolate drizzled with smooth caramel sauce and topped with caramel-infused whipped cream.",
                    foodImg: "https://example.com/caramel-hot-chocolate.jpg",
                    price: 190,
                    addedToCart: false
                },
                {
                    id: "95",
                    title: "Spiced Hot Chocolate",
                    description: "Warm and comforting hot chocolate infused with a blend of aromatic spices like cinnamon and nutmeg.",
                    foodImg: "https://example.com/spiced-hot-chocolate.jpg",
                    price: 180,
                    addedToCart: false
                },
                {
                    id: "96",
                    title: "Dark Chocolate Hot Chocolate",
                    description: "Intensely rich hot chocolate made with premium dark chocolate and a hint of sea salt.",
                    foodImg: "https://example.com/dark-chocolate-hot-chocolate.jpg",
                    price: 200,
                    addedToCart: false
                }
            ]
        },
        {
            type: "Burger",
            active: false,
            image: "https://img.freepik.com/free-psd/pictou-county-pizza-isolated-transparent-background_191095-32844.jpg?size=626&ext=jpg&ga=GA1.1.1653169833.1708889579&semt=sph",
            items: [
                {
                    id: "101",
                    title: "Classic Beef Burger",
                    description: "Juicy beef patty topped with fresh lettuce, tomato, onion, and cheese on a toasted bun.",
                    foodImg: "https://example.com/classic-beef-burger.jpg",
                    price: 200,
                    addedToCart: false
                },
                {
                    id: "102",
                    title: "Chicken Bacon Ranch Burger",
                    description: "Grilled chicken breast topped with crispy bacon, ranch dressing, lettuce, and tomato on a sesame seed bun.",
                    foodImg: "https://example.com/chicken-bacon-ranch-burger.jpg",
                    price: 220,
                    addedToCart: false
                },
                {
                    id: "103",
                    title: "Veggie Burger",
                    description: "A flavorful veggie patty made with black beans, corn, and spices, topped with avocado, lettuce, and tomato on a whole wheat bun.",
                    foodImg: "https://example.com/veggie-burger.jpg",
                    price: 180,
                    addedToCart: false
                },
                {
                    id: "104",
                    title: "Mushroom Swiss Burger",
                    description: "Beef patty topped with sautéed mushrooms, Swiss cheese, lettuce, and garlic aioli on a brioche bun.",
                    foodImg: "https://example.com/mushroom-swiss-burger.jpg",
                    price: 210,
                    addedToCart: false
                },
                {
                    id: "105",
                    title: "BBQ Bacon Burger",
                    description: "Beef patty smothered in tangy barbecue sauce, topped with crispy bacon, cheddar cheese, and crispy onion straws on a brioche bun.",
                    foodImg: "https://example.com/bbq-bacon-burger.jpg",
                    price: 230,
                    addedToCart: false
                },
                {
                    id: "106",
                    title: "Spicy Jalapeno Burger",
                    description: "Beef patty topped with spicy jalapenos, pepper jack cheese, chipotle mayo, lettuce, and tomato on a jalapeno cheddar bun.",
                    foodImg: "https://example.com/spicy-jalapeno-burger.jpg",
                    price: 220,
                    addedToCart: false
                }
            ]
        }
    ]
};

export default foodData;
