import React, { useContext, useState } from 'react'
import MenuCard from './MenuCard'
import CardComp from './CardComp'
import { CartContext } from '../../Context/CardContext';


const Menu = ({ selectedCategory, Mindex }) => {
    // console.log(`the selected item is ${selectedCategory}`)
    // console.log(`the index is ${Mindex}`);
    const { cart } = useContext(CartContext);
    return (
        <>
            <div className="menu_Container">
                <div className="menu_content">
                    {selectedCategory.items.map((item, index) => (
                        <MenuCard key={index} item={item} Mindex={Mindex} />
                    ))}
                </div>
                <div className="flexOrderCard">
                    <div className="orderCard">
                        {cart.length === 0 ? (<div className="orderPadding" >
                            <img src="https://s3.ap-south-1.amazonaws.com/resources.siporacoffee.com/Documents/2.png" alt="" />
                            <div className="orderCardContent">
                                <h1 className='orderCardHeading'>YOUR CART IS EMPTY</h1>
                                <p className="orderCardPara"> Please add some items from the menu. </p>
                            </div>
                        </div>) : <div className="FoodCardItem">
                            {cart.map((item, index) => (
                                <CardComp key={index} cardStructure={item} />
                            ))}
                        </div>}
                    </div>
                    {cart.length > 0 && (
                        <div className="FoodCardButton">
                            <button className='OrderNow'>CHECKOUT </button>
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}

export default Menu
