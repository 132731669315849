import React from "react";

const Googlemap = () => {
    return (
        <div className="googleMapContainer">
            <h1 className="heading">
                Find Us <span>why choose us </span>
            </h1>
            <div className="googleLocation" style={{ marginTop: "1rem" }}>
                {/* <iframe
                    className="map"
                    id="googleMapId"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27808.548568035694!2d79.10311652496269!3d29.397550056310354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390a139dca30a92d%3A0x61ad11a71c820859!2sRamnagar%2C%20Uttarakhand%20244715!5e0!3m2!1sen!2sin!4v1704876799179!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe> */}
                <iframe
                    className="map"
                    id="googleMapId"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3504.178638578437!2d77.397573!3d28.564398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDMzJzUxLjgiTiA3N8KwMjMnNTEuMyJF!5e0!3m2!1sen!2sin!4v1710355531370!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default Googlemap;
