import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainHome from "./MainHome";
// import Navbar from "../components/Navbar";
import FooterCompo from '../components/FooterCompo'
import MainMenu from "./MainMenu";
import ZomatoSwiggy from "../components/zomatoSwiggy";

function AppRouter() {
    return <>
        <BrowserRouter >
            {/* <Navbar /> */}
            <Routes>
                <Route path="/" element={<MainHome />} />
                <Route path="/Home" element={<MainHome />} />
                <Route path="/About" element={<MainHome />} />
                <Route path="/Menu" element={<MainMenu />} />
                <Route path="/Review" element={<MainHome />} />
                <Route path="/Book" element={<MainHome />} />
                <Route path="/zomatoSwiggy" element={<ZomatoSwiggy />} />
            </Routes>
            <FooterCompo />
        </BrowserRouter >
    </>;
}

export default AppRouter;
