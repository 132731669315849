import React from 'react'
import about1 from '../Image/about-img.png';
import about2 from '../Image/about-icon-1.png';
import about3 from '../Image/about-icon-2.png';
import about4 from '../Image/about-icon-3.png';

const About = () => {
    return (
        <>
            <section className="about" id="about">
                <h1 className="heading"> about us <span>why choose us </span></h1>
                <div className="row">
                    <div className="image">
                        <img src={about1} alt="" />
                    </div>
                    <div className="content">
                        <h3 className="title">what's make our coffee special!</h3>
                        <p>At Sipora, our coffee is meticulously hand-brewed, ensuring a unique and artisanal experience
                            with every sip. Crafted with care and expertise, our hand-brewed coffee embodies the essence of quality,
                            freshness, and attention to detail, delivering a truly exceptional and memorable taste sensation.
                        </p>

                            <div className="icons-container">
                                <div className="icons">
                                    <img src={about2} alt="" />
                                    <h3>quality coffee</h3>
                                </div>
                                <div className="icons">
                                    <img src={about4} alt="" />
                                    <h3>free delivery</h3>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
