import React, { createContext, useReducer } from "react";
import foodData from "../api";

export const FoodCategoryContext = createContext();

const UPDATE_FOOD_CARD = "UPDATE_FOOD_CARD";
const REMOVE_FOOD_CARD = "REMOVE_FOOD_CARD";
const REMOVE_FOOD_CARD_WITHOUT_ID = "REMOVE_FOOD_CARD_WITHOUT_ID";

const initialState = {
    foodData: foodData,
};

const foodCardReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_FOOD_CARD:
            const { ItemId, ParentId } = action.payload;
            // console.log(ItemId);
            // console.log(ParentId);
            let updatedFoodData = { ...state };
            updatedFoodData.foodData.foodTypes[ParentId].items = updatedFoodData.foodData.foodTypes[ParentId].items.map(item => {
                if (item.id === ItemId) {
                    return { ...item, addedToCart: true };
                } else {
                    return item;
                }
            });
            // console.log(updatedFoodData);
            return updatedFoodData;
        case REMOVE_FOOD_CARD: {
            const { ItemId, ParentId } = action.payload;
            // console.log(ItemId);
            // console.log(ParentId);
            let updatedFoodData = { ...state };
            updatedFoodData.foodData.foodTypes[ParentId].items = updatedFoodData.foodData.foodTypes[ParentId].items.map(item => {
                if (item.id === ItemId) {
                    return { ...item, addedToCart: false };
                } else {
                    return item;
                }
            });
            return updatedFoodData;
        }
        case REMOVE_FOOD_CARD_WITHOUT_ID :{
            const { ItemId } = action.payload;
            console.log(ItemId);
            let updatedFoodData = {...state};
            updatedFoodData.foodData.foodTypes = updatedFoodData.foodData.foodTypes.map(Fooditems =>{
                Fooditems.items = Fooditems.items.map(item =>{
                    if(item.id === ItemId){
                        return {...item, addedToCart: false};
                    }
                    else{
                        return item;
                    }
                })
                return Fooditems;
            })
            return updatedFoodData;
        }
        default:
            return state;
    }
};


export const FoodCardContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(foodCardReducer, initialState);
    return (
        <FoodCategoryContext.Provider value={{
            ...state,
            dispatch,
        }}>
            {children}
        </FoodCategoryContext.Provider>
    );
};
