import React, { useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import foodData from '../../api';
import { Autoplay, Navigation } from 'swiper/modules';
import { FoodCategoryContext } from '../../Context/FoodCategoryContext';

export default function MenuHeader({ onCategorySelect }) {
    const { foodData } = useContext(FoodCategoryContext);
    // console.log(foodData); 


    const handleCategoryClick = (category, index) => {
        // console.log(category)
        // console.log(index)
        onCategorySelect(category[index],index);
    };

    return (
        <div className='menu_header'>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                speed={400}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    // Breakpoints for smaller screens
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    480: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 15,
                    },
                    // Breakpoints for larger screens
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 6,
                        spaceBetween: 25,
                    },
                    1440: {
                        slidesPerView: 7,
                        spaceBetween: 30,
                    },
                    1920: {
                        slidesPerView: 8,
                        spaceBetween: 35,
                    }
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
            >
                {foodData.foodTypes.map((foodType, index) => (
                    <SwiperSlide key={index} className="menuParent">
                        <div className="menu_item"
                            onClick={() => handleCategoryClick(foodData.foodTypes, index)}>
                            <img src={foodType.image} alt={`Food Image ${index + 1}`} />
                            <span className="category_name">{foodType.type}</span>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

