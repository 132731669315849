import React from "react";
import Z from "../Image/Z.png";
import S from "../Image/S.png";

const zomatoSwiggy = () => {
    return (
        <>
            <div className="zomatoSwiggyContainer">
                <div className="zomatoSwiggyBox1">
                    <h1 className="zomatoSwiggyHeading">
                        Explore our delicious, sustainable dishes{" "}
                    </h1>
                    <p className="zomatoSwiggyPara">
                        savor our diverse menu crafted form the freshest ingredients and
                        indulge in an unforgettable dining experience , whether for a casual
                        meal or a special occasion.
                    </p>
                    <div className="zomatoSwiggyBtn">
                        <a href="https://link.zomato.com/xqzv/rshare?id=7071364030563ab4 " className="zomatoBtn">
                            <img src={Z} alt="" />
                            <span style={{ color: "red" }}>Zomato </span>
                        </a>
                        <a href="https://www.swiggy.com/restaurants/sipora-coffee-116-main-road-sector-50-noida-1-857595" className="SwiggyBtn">
                            <img src={S} alt="" />
                            <span style={{ color: "orangered" }}>swiggy</span>
                        </a>
                    </div>
                </div>
                <div className="zomatoSwiggyBox2">
                    <div className="zomatoSwiggyInputBox">
                        <div className="zomatoSwiggyImage">
                            <img
                                src="https://img.freepik.com/free-photo/mouth-watering-delicious-sandwiches-fries-chicken-nuggets-black-board-fries-pepper-dark-gray-blurred-surface_179666-42705.jpg?w=360&t=st=1717912229~exp=1717912829~hmac=0fb30e3438465a039d17f5bc43b29584e349f632667c7a15425da87912537a5d"
                                alt=""
                            />
                        </div>
                        <div className="zomatoSwiggyTextBox">
                            <span className="zomatoSwiggyText"> we are available on :</span>
                            <span id="websiteBtn">vist to website </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default zomatoSwiggy;
